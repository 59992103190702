import React from "react";

export class SettingsBar extends React.Component {

    startForwardAnimation1() {
        let anim = document.getElementById("animforward1");
        anim.beginElement()
      }
      startBackwardAnimation1() {
        let anim = document.getElementById("animbackward1");
        anim.beginElement()
      }
      startForwardAnimation2() {
        let anim = document.getElementById("animforward2");
        anim.beginElement()
      }
      startBackwardAnimation2() {
        let anim = document.getElementById("animbackward2");
        anim.beginElement()
      }
      startForwardAnimation3() {
        let anim = document.getElementById("animforward3");
        anim.beginElement()
      }
      startBackwardAnimation3() {
        let anim = document.getElementById("animbackward3");
        anim.beginElement()
      }
      startForwardAnimation4() {
        let anim = document.getElementById("animforward4");
        anim.beginElement()
      }
      startBackwardAnimation4() {
        let anim = document.getElementById("animbackward4");
        anim.beginElement()
      }
      startForwardAnimation5() {
        let anim = document.getElementById("animforward5");
        anim.beginElement()
      }
      startBackwardAnimation5() {
        let anim = document.getElementById("animbackward5");
        anim.beginElement()
      }
      startForwardAnimation6() {
        let anim = document.getElementById("animforward6");
        anim.beginElement()
      }
      startBackwardAnimation6() {
        let anim = document.getElementById("animbackward6");
        anim.beginElement()
      }
      startForwardAnimation7() {
        let anim = document.getElementById("animforward7");
        anim.beginElement()
      }
      startBackwardAnimation7() {
        let anim = document.getElementById("animbackward7");
        anim.beginElement()
      }
      startForwardAnimation8() {
        let anim = document.getElementById("animforward8");
        anim.beginElement()
      }
      startBackwardAnimation8() {
        let anim = document.getElementById("animbackward8");
        anim.beginElement()
      }
      startForwardAnimation9() {
        let anim = document.getElementById("animforward9");
        anim.beginElement()
      }
      startBackwardAnimation9() {
        let anim = document.getElementById("animbackward9");
        anim.beginElement()
      }
      


    render() {
      return <div class="settings_container">

        <button             
          class="settings_button"
          id="button1"
          onMouseOver={() => this.startForwardAnimation1()}
          onMouseOut={() => this.startBackwardAnimation1()}
          onClick={() => this.props.presetOne()}>
          1
        </button>

        <button             
          class="settings_button"
          id="button2"
          onMouseOver={() => this.startForwardAnimation2()}
          onMouseOut={() => this.startBackwardAnimation2()}
          onClick={() => this.props.presetTwo()}>
          2
        </button>

        <button             
          class="settings_button"
          id="button3"
          onMouseOver={() => this.startForwardAnimation3()}
          onMouseOut={() => this.startBackwardAnimation3()}
          onClick={() => this.props.presetThree()}>
          3
        </button>

        <button             
          class="settings_button"
          id="button4"
          onMouseOver={() => this.startForwardAnimation4()}
          onMouseOut={() => this.startBackwardAnimation4()}
          onClick={() => this.props.presetFour()}>
          4
        </button>

        <button             
          class="settings_button"
          id="button5"
          onMouseOver={() => this.startForwardAnimation5()}
          onMouseOut={() => this.startBackwardAnimation5()}
          onClick={() => this.props.presetFive()}>
          5
        </button>

        <button             
          class="settings_button"
          id="button6"
          onMouseOver={() => this.startForwardAnimation6()}
          onMouseOut={() => this.startBackwardAnimation6()}
          onClick={() => this.props.presetSix()}>
          6
        </button>

        <button             
          class="settings_button"
          id="button7"
          onMouseOver={() => this.startForwardAnimation7()}
          onMouseOut={() => this.startBackwardAnimation7()}
          onClick={() => this.props.presetSeven()}>
          7
        </button>

        <button             
          class="settings_button"
          id="button8"
          onMouseOver={() => this.startForwardAnimation8()}
          onMouseOut={() => this.startBackwardAnimation8()}
          onClick={() => this.props.presetEight()}>
          8
        </button>

        <button             
          class="settings_button"
          id="button9"
          onMouseOver={() => this.startForwardAnimation9()}
          onMouseOut={() => this.startBackwardAnimation9()}
          onClick={() => this.props.presetNine()}>
          9
        </button>
        
    {/* Button Displacement Filter */}
    <svg>
            <defs>
                <filter id='turbdisp1'>
                    <feTurbulence id="turbulence" baseFrequency="0.05" numOctaves="3" seed="0"/>
                    <feDisplacementMap id="displacement1" in="SourceGraphic" in2="noise" scale="0"/>
                </filter>
                <filter id='turbdisp2'>
                    <feTurbulence id="turbulence" baseFrequency="0.05" numOctaves="3" seed="1"/>
                    <feDisplacementMap id="displacement2" in="SourceGraphic" in2="noise" scale="0"/>
                </filter>
                <filter id='turbdisp3'>
                    <feTurbulence id="turbulence" baseFrequency="0.05" numOctaves="3" seed="2"/>
                    <feDisplacementMap id="displacement3" in="SourceGraphic" in2="noise" scale="0"/>
                </filter>
                <filter id='turbdisp4'>
                    <feTurbulence id="turbulence" baseFrequency="0.05" numOctaves="3" seed="3"/>
                    <feDisplacementMap id="displacement4" in="SourceGraphic" in2="noise" scale="0"/>
                </filter>
                <filter id='turbdisp5'>
                    <feTurbulence id="turbulence" baseFrequency="0.05" numOctaves="3" seed="4"/>
                    <feDisplacementMap id="displacement5" in="SourceGraphic" in2="noise" scale="0"/>
                </filter>
                <filter id='turbdisp6'>
                    <feTurbulence id="turbulence" baseFrequency="0.05" numOctaves="3" seed="5"/>
                    <feDisplacementMap id="displacement6" in="SourceGraphic" in2="noise" scale="0"/>
                </filter>
                <filter id='turbdisp7'>
                    <feTurbulence id="turbulence" baseFrequency="0.05" numOctaves="3" seed="6"/>
                    <feDisplacementMap id="displacement7" in="SourceGraphic" in2="noise" scale="0"/>
                </filter>
                <filter id='turbdisp8'>
                    <feTurbulence id="turbulence" baseFrequency="0.05" numOctaves="3" seed="7"/>
                    <feDisplacementMap id="displacement8" in="SourceGraphic" in2="noise" scale="0"/>
                </filter>
                <filter id='turbdisp9'>
                    <feTurbulence id="turbulence" baseFrequency="0.05" numOctaves="3" seed="8"/>
                    <feDisplacementMap id="displacement9" in="SourceGraphic" in2="noise" scale="0"/>
                </filter>
                <filter id='turbdisp10'>
                    <feTurbulence id="turbulence" baseFrequency="0.05" numOctaves="3" seed="9"/>
                    <feDisplacementMap id="displacement10" in="SourceGraphic" in2="noise" scale="0"/>
                </filter>
                <filter id='turbdisp11'>
                    <feTurbulence id="turbulence" baseFrequency="0.05" numOctaves="3" seed="10"/>
                    <feDisplacementMap id="displacement11" in="SourceGraphic" in2="noise" scale="0"/>
                </filter>
                <filter id='turbdisp12'>
                    <feTurbulence id="turbulence" baseFrequency="0.05" numOctaves="3" seed="11"/>
                    <feDisplacementMap id="displacement12" in="SourceGraphic" in2="noise" scale="0"/>
                </filter>
                <filter id='turbdisp13'>
                    <feTurbulence id="turbulence" baseFrequency="0.05" numOctaves="3" seed="12"/>
                    <feDisplacementMap id="displacement13" in="SourceGraphic" in2="noise" scale="0"/>
                </filter>
                <filter id='turbdisp14'>
                    <feTurbulence id="turbulence" baseFrequency="0.05" numOctaves="3" seed="13"/>
                    <feDisplacementMap id="displacement14" in="SourceGraphic" in2="noise" scale="0"/>
                </filter>

                <filter id='dialoguedisp'>
                  <feTurbulence id="diaturb" baseFrequency="0.025" type="fractalNoise" numOctaves="1" seed="1" result="MIDDLE-SPLASH_10" />
                  <feDisplacementMap in="SourceGraphic" in2="noise" scale="5" result="MIDDLE-SPLASH_30"/>
                  <feMorphology id="diamorph" operator="dilate" radius="1"/>
                </filter>
            </defs>
            {/* onMouseOver Animation */}
            <animate
                id="animforward1" 
                xlinkHref="#displacement1"
                attributeName="scale"
                from="0"
                to="5"
                dur="1s"
                begin="indefinite"
                fill="freeze"
            />
            {/* onMouseOut Animation */}
            <animate
                id="animbackward1" 
                xlinkHref="#displacement1"
                attributeName="scale"
                from="5"
                to="0"
                dur="1s"
                begin="indefinite"
                fill="freeze"
            />
            {/* onMouseOver Animation */}
            <animate
                id="animforward2" 
                xlinkHref="#displacement2"
                attributeName="scale"
                from="0"
                to="5"
                dur="1s"
                begin="indefinite"
                fill="freeze"
            />
            {/* onMouseOut Animation */}
            <animate
                id="animbackward2" 
                xlinkHref="#displacement2"
                attributeName="scale"
                from="5"
                to="0"
                dur="1s"
                begin="indefinite"
                fill="freeze"
            />
            {/* onMouseOver Animation */}
            <animate
                id="animforward3" 
                xlinkHref="#displacement3"
                attributeName="scale"
                from="0"
                to="5"
                dur="1s"
                begin="indefinite"
                fill="freeze"
            />
            {/* onMouseOut Animation */}
            <animate
                id="animbackward3" 
                xlinkHref="#displacement3"
                attributeName="scale"
                from="5"
                to="0"
                dur="1s"
                begin="indefinite"
                fill="freeze"
            />
            {/* onMouseOver Animation */}
            <animate
                id="animforward4" 
                xlinkHref="#displacement4"
                attributeName="scale"
                from="0"
                to="5"
                dur="1s"
                begin="indefinite"
                fill="freeze"
            />
            {/* onMouseOut Animation */}
            <animate
                id="animbackward4" 
                xlinkHref="#displacement4"
                attributeName="scale"
                from="5"
                to="0"
                dur="1s"
                begin="indefinite"
                fill="freeze"
            />
            {/* onMouseOver Animation */}
            <animate
                id="animforward5" 
                xlinkHref="#displacement5"
                attributeName="scale"
                from="0"
                to="5"
                dur="1s"
                begin="indefinite"
                fill="freeze"
            />
            {/* onMouseOut Animation */}
            <animate
                id="animbackward5" 
                xlinkHref="#displacement5"
                attributeName="scale"
                from="5"
                to="0"
                dur="1s"
                begin="indefinite"
                fill="freeze"
            />
            {/* onMouseOver Animation */}
            <animate
                id="animforward6" 
                xlinkHref="#displacement6"
                attributeName="scale"
                from="0"
                to="5"
                dur="1s"
                begin="indefinite"
                fill="freeze"
            />
            {/* onMouseOut Animation */}
            <animate
                id="animbackward6" 
                xlinkHref="#displacement6"
                attributeName="scale"
                from="5"
                to="0"
                dur="1s"
                begin="indefinite"
                fill="freeze"
            />
            {/* onMouseOver Animation */}
            <animate
                id="animforward7" 
                xlinkHref="#displacement7"
                attributeName="scale"
                from="0"
                to="5"
                dur="1s"
                begin="indefinite"
                fill="freeze"
            />
            {/* onMouseOut Animation */}
            <animate
                id="animbackward7" 
                xlinkHref="#displacement7"
                attributeName="scale"
                from="5"
                to="0"
                dur="1s"
                begin="indefinite"
                fill="freeze"
            />
            {/* onMouseOver Animation */}
            <animate
                id="animforward8" 
                xlinkHref="#displacement8"
                attributeName="scale"
                from="0"
                to="5"
                dur="1s"
                begin="indefinite"
                fill="freeze"
            />
            {/* onMouseOut Animation */}
            <animate
                id="animbackward8" 
                xlinkHref="#displacement8"
                attributeName="scale"
                from="5"
                to="0"
                dur="1s"
                begin="indefinite"
                fill="freeze"
            />
            {/* onMouseOver Animation */}
            <animate
                id="animforward9" 
                xlinkHref="#displacement9"
                attributeName="scale"
                from="0"
                to="5"
                dur="1s"
                begin="indefinite"
                fill="freeze"
            />
            {/* onMouseOut Animation */}
            <animate
                id="animbackward9" 
                xlinkHref="#displacement9"
                attributeName="scale"
                from="5"
                to="0"
                dur="1s"
                begin="indefinite"
                fill="freeze"
            />
            {/* onMouseOver Animation */}
            <animate
                id="animforward10" 
                xlinkHref="#displacement10"
                attributeName="scale"
                from="0"
                to="5"
                dur="1s"
                begin="indefinite"
                fill="freeze"
            />
            {/* onMouseOut Animation */}
            <animate
                id="animbackward10" 
                xlinkHref="#displacement10"
                attributeName="scale"
                from="5"
                to="0"
                dur="1s"
                begin="indefinite"
                fill="freeze"
            />
            {/* onMouseOver Animation */}
            <animate
                id="animforward11" 
                xlinkHref="#displacement11"
                attributeName="scale"
                from="0"
                to="5"
                dur="1s"
                begin="indefinite"
                fill="freeze"
            />
            {/* onMouseOut Animation */}
            <animate
                id="animbackward11" 
                xlinkHref="#displacement11"
                attributeName="scale"
                from="5"
                to="0"
                dur="1s"
                begin="indefinite"
                fill="freeze"
            />
            {/* onMouseOver Animation */}
             <animate
                id="animforward12" 
                xlinkHref="#displacement12"
                attributeName="scale"
                from="0"
                to="5"
                dur="1s"
                begin="indefinite"
                fill="freeze"
            />
            {/* onMouseOut Animation */}
            <animate
                id="animbackward12" 
                xlinkHref="#displacement12"
                attributeName="scale"
                from="5"
                to="0"
                dur="1s"
                begin="indefinite"
                fill="freeze"
            />
            {/* onMouseOver Animation */}
            <animate
                id="animforward13" 
                xlinkHref="#displacement13"
                attributeName="scale"
                from="0"
                to="5"
                dur="1s"
                begin="indefinite"
                fill="freeze"
            />
            {/* onMouseOut Animation */}
            <animate
                id="animbackward13" 
                xlinkHref="#displacement13"
                attributeName="scale"
                from="5"
                to="0"
                dur="1s"
                begin="indefinite"
                fill="freeze"
            />
            {/* onMouseOver Animation */}
            <animate
                id="animforward14" 
                xlinkHref="#displacement14"
                attributeName="scale"
                from="0"
                to="5"
                dur="1s"
                begin="indefinite"
                fill="freeze"
            />
            {/* onMouseOut Animation */}
            <animate
                id="animbackward14" 
                xlinkHref="#displacement14"
                attributeName="scale"
                from="5"
                to="0"
                dur="1s"
                begin="indefinite"
                fill="freeze"
            />
        </svg>
  
</div>
;
    }
  }

//Export Anim Functions
export function startForwardAnimation10() {
  let anim = document.getElementById("animforward10");
  anim.beginElement()
}
export function startBackwardAnimation10() {
  let anim = document.getElementById("animbackward10");
  anim.beginElement()
}
export function startForwardAnimation11() {
  let anim = document.getElementById("animforward11");
  anim.beginElement()
}
export function startBackwardAnimation11() {
  let anim = document.getElementById("animbackward11");
  anim.beginElement()
}
export function startForwardAnimation12() {
  let anim = document.getElementById("animforward12");
  anim.beginElement()
}
export function startBackwardAnimation12() {
  let anim = document.getElementById("animbackward12");
  anim.beginElement()
}
export function startForwardAnimation13() {
  let anim = document.getElementById("animforward13");
  anim.beginElement()
}
export function startBackwardAnimation13() {
  let anim = document.getElementById("animbackward13");
  anim.beginElement()
}
export function startForwardAnimation14() {
  let anim = document.getElementById("animforward14");
  anim.beginElement()
}
export function startBackwardAnimation14() {
  let anim = document.getElementById("animbackward14");
  anim.beginElement()
}






