import React from "react";

//Question Start Array
export var randomStart = [
  'please show me a', 
  'please draw me a', 
  'please paint me a'
];

//Question Middle Array
export var randomMiddle = [
  'n inanimate object', 
  ' valued item', 
  ' beautiful thing',
  ' detested item',
  ' forgotten object',
  'n animal',
  ' building',
  ' word',
  ' plant',
  ' tree',
  'n ornament',
  ' sound'
];

//Question End Array
export var randomEnd = [
  ' that is your favourite...', 
  ' that is closest to you...', 
  ' that you value most...', 
  ' that is your most treasured...',
  ' that you hate...',
  ' that you love...',
  ' that reminds you of the past...',
  ' that you most desire'
];

//Question End Array
export var responseArray = [
  'i am closer to remembering.',
  'i am moved to tears.',
  'i can see what you mean.',
  'i hate it here.',
  'i don\'t really understand yet.',
  'that was very exciting.',
  'you are very talented.',
  'you seem at a loss.',
  'that is a form i once knew.',
  'okay.  let\'s try again.',
];

  //Initialise Question Construction Array
export var questionOutputArray = ['start', 'middle', 'end'];
//Initialise Question Construction Array
export var questionOutput = 'string';

//random no repeat
export function randomNoRepeat(min, max, lastRandom){
    let random;

    if (lastRandom === undefined) {
      random = Math.floor(Math.random() * (max - min + 1)) + min;
    }
    else {
      random = Math.floor(Math.random() * (max - min    )) + min;
        if (random >= lastRandom) random += 1;
    }
    lastRandom = random;
    return lastRandom;
}

export class DialogueBar extends React.Component {

  render() {

      return <div>
      </div>;
    }
  }

//TypeWriter Constructor
export class TypeWriter {
  constructor(txtElement, words) {
      this.txtElement = txtElement;
      this.words = words;
      this.txt = '';
      this.typeTime = 10;
      this.isCompleted = false;
      //Random Displacement Seed
      document.getElementById("diaturb").setAttribute("seed", Math.floor(Math.random()*100));
      //Random Thickness
      document.getElementById("diamorph").setAttribute("radius", Math.random());
      this.type();
  }

  type () {
      // Get full text of current question
      const fullTxt = this.words;

      //Add Char
      this.txt = fullTxt.substring(0, this.txt.length + 1);

      //Insert txt into element
      this.txtElement.innerHTML = `<span class="txt">${this.txt}</span>`;

      // Initial type Speed
      let typeSpeed = ((Math.random()/2) + 0.5)*50;

      //Call Next Letter if needed or Stop
      if(this.txt === fullTxt){
        this.txt = '';
        this.typeTime = 10;
        this.isCompleted = true;
      } else { 
        setTimeout(() => this.type(), typeSpeed); }
  }
  get isCompletedCheck(){
    return this.isCompleted;
  }
}

//TypeWriter Constructor
export class ModalTypeWriter {
  constructor(txtElement, words) {
      this.txtElement = txtElement;
      this.words = words;
      this.txt = '';
      this.typeTime = 10;
      this.isCompleted = false;
      //Random Displacement Seed
      document.getElementById("diaturb").setAttribute("seed", Math.floor(Math.random()*100));
      //Random Thickness
      document.getElementById("diamorph").setAttribute("radius", Math.random() * 0.5);
      this.type();
  }

  type () {
      // Get full text of current question
      const fullTxt = this.words;

      //Add Char
      this.txt = fullTxt.substring(0, this.txt.length + 1);

      //Insert txt into element
      this.txtElement.innerHTML = `<span class="txt">${this.txt}</span>`;

      // Initial type Speed
      let typeSpeed = 50;

      //Call Next Letter if needed or Stop
      if(this.txt === fullTxt){
        this.txt = '';
        this.typeTime = 10;
        this.isCompleted = true;
      } else { 
        setTimeout(() => this.type(), typeSpeed); }
  }
  get isCompletedCheck(){
    return this.isCompleted;
  }
}
