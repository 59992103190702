import React, { Component } from "react";
import { Bus, Uniform, Node, NearestCopy, LinearCopy } from "gl-react";
import { Surface } from "gl-react-dom";

import { paintShaderExport } from "./painter";
import { TextureLookupExport } from "./textureLookup";
import { BlurXY } from "./blurXY";
import timeLoop from "./timeLoop";

function getPosition (e) {
  const rect = e.target.getBoundingClientRect();
  return [
    (e.clientX - rect.left) / rect.width,
    (rect.bottom - e.clientY) / rect.height,
  ];
}

//Reaction Diffusion single operation
const ReacDiff = ({ rDShader, width, height, reset, resetTexture, diffusionScale, backgroundMix, displacementAmount, feed_A, feed_B, kill_A, kill_B, paintMix }) =>
  <Node
    shader={rDShader}
    width={width}
    height={height}
    backbuffering
    sync
    uniforms={{
      texture: reset ? resetTexture : Uniform.Backbuffer,
      width,
      height,
      diffusionScale,
      backgroundMix,
      displacementAmount,
      feed_A,
      feed_B,
      kill_A,
      kill_B,
      paintMix
    }}
  />;

var ReacDiffLoop = timeLoop(ReacDiff, {refreshRate: 60});

export default class FeedbackCanvas extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      
      //Screen Size
      width: 0,
      height: 0,
      
      //React Diffusion
      reset: false,
      resetTexture: null,

      //Texture Lookup
      lookup_res: [720.0, 720.0],
      fbSurfacePixels: null,

      //Painter
      drawing: false,
      color: [1,0,0,1],
      center: [0.5,0.5],
      brushRadius: 0.01,

    };
      
    //Update Screen Size on change
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  render() {

    //Trigger Capture after reaction
    if (this.props.clearToggle){
      this.setState({fbSurfacePixels: this.refs.surface.capture(), reset: true});
      setTimeout(() => this.setPixels(), 100);
    }

    if (this.props.captureToggle){
      this.setState({fbSurfacePixels: this.refs.surface.capture()});
      setTimeout(() => this.setPixels(), 100);
    }

    const { reset } = this.state;
    const { 

      //Reaction Diffusion Settings
      rDShader,
      diffusionScale,
      backgroundMix,
      displacementAmount,
      paintMix,
      feed_A,
      feed_B,
      kill_A,
      kill_B,
      diffusionDownscale,
      //Blur Settings
      factor,
      //Texture Lookup Settings
      indexQuantise,
      index_colorMix,
      transform_colorMix,
      scale,
      numCols,
      numRows,
      tileSheet,
      lookup_res,
      //Painter Settings
      painterDownscale,

        } = this.props;

    return(
      
      <Surface
      ref="surface"
      width={(this.state.width / 16) * 14}
      height={(this.state.height /9) * 8}
      webglContextAttributes={{ preserveDrawingBuffer: true }}
      onMouseUp={this.onMouseUp}
      onMouseDown={this.onMouseDown}
      onMouseMove={this.onMouseMove}
      onMouseLeave={this.onMouseLeave}
      >
         {/*Painter Bus*/}
        <Bus ref="paintbus">
          <NearestCopy>
            <Node
              shader={paintShaderExport.Paint}
              uniforms={this.state}
              clear={null}
              width={((this.state.width / 16) * 14) / painterDownscale}
              height={((this.state.width / 16) * 14) / painterDownscale} />
          </NearestCopy>
        </Bus>

        {/*Reaction Diffusion Loop Bus*/}
        <Bus ref="loopbus">
        <LinearCopy>
          <BlurXY factor={factor}>
            <ReacDiffLoop
              rDShader={rDShader}
              reset={reset}
              width={((this.state.width / 16) * 14) / diffusionDownscale}
              height={((this.state.width / 16) * 14) / diffusionDownscale}
              diffusionScale={diffusionScale}
              backgroundMix={backgroundMix}
              displacementAmount={displacementAmount}
              feed_A={feed_A}
              feed_B={feed_B}
              kill_A={kill_A}
              kill_B={kill_B}
              resetTexture={() => this.refs.paintbus}
              paintMix={paintMix}
              />
            </BlurXY>
          </LinearCopy>
        </Bus>

        {/*Retexture Output*/}
        <LinearCopy>
          <TextureLookupExport
            tileSheet={tileSheet}
            reset={reset}
            lookup={() => this.refs.loopbus}
            lookup_res={lookup_res}
            numCols={numCols}
            numRows={numRows}
            scale={scale}
            indexQuantise={indexQuantise}
            index_colorMix={index_colorMix}
            transform_colorMix={transform_colorMix}
            width={((this.state.width / 16) * 14)}
            height={((this.state.width / 16) * 14)}
           />
        </LinearCopy>
    </Surface>
    )
  }

  //Check for window resize
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  setPixels(){
    this.props.data.capturePixels(this.state.fbSurfacePixels);
  }

   //Mouse setState Functions
   onMouseDown = (e) => {
    if (this.props.modalToggle && this.props.isTyping === false) {
      this.setState({
        reset: true,
        drawing: true,
        center: getPosition(e),    
      });
    }
   
  };

  onMouseUp = () => {
    this.setState({
      drawing: false,
      reset: false
    });
  };

  onMouseMove = (e) => {
    if (this.state.drawing) {
      this.setState({
        center: getPosition(e),
        brushRadius: 0.03 + 0.01 * Math.cos(Date.now() / 1000),
        color: [ (Math.cos(Date.now() / 1000) + 1.0 ) * 0.5 * 0.9, 1.0, 1.0, 1.0, ],
      });
    }
  };

  onMouseLeave = () => {
    this.setState({
      drawing: false
    });
  };
}