import React, { Component } from "react";
import Modal from "react-modal";

Modal.setAppElement('#root')

export default class InfoModal extends Component {
    
    constructor(props) {
        super(props)
    
        this.state = {
            modalIsOpen: true
        }
      }


    render() {  
    
    return  <div class="info">
                      <span id="infoModalTitle">INSTRUCTIONS</span>
                      <br />
                      <br />
                      <br />
                      <img src={require("../Images/airbrush_cursor.png")} alt="Airbrush Icon"/> <span class="infoModalTextSmall">Use this tool to draw your response</span>
                      <br />
                      <br />
                      <img src={require("../Images/Buttons/butt01.png")} alt="Preset Button"/> <span class="infoModalTextSmall">Select different preset palettes from the Methods</span>
                      <br />
                      <br />
                      <img id="simimg" src={require("../Images/Buttons/buttsim.png")} alt="Simulate Button"/> <span class="infoModalText">Process image after drawing response</span>
                      <br />
                      <br />
                      <img id="questimg" src={require("../Images/Buttons/buttquest.png")} alt="Next Question Button"/> <span class="infoModalText">Proceed on to the next question </span>

                      <button id="closebutton" onClick={() => this.props.closeInfoModal()}>x</button>
            </div>;
    };
  
  }
  