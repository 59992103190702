import React, { Component } from "react";
import './App.css';
import Tone from "tone";
import createjs from 'preload-js'
import FeedbackCanvas from './Components/feedbackCanvas';
import Modal from "react-modal";
import { SettingsBar, startForwardAnimation10, startBackwardAnimation10, startForwardAnimation11, startBackwardAnimation11, startForwardAnimation12, startBackwardAnimation12 } from "./Components/settingsBar";
import IntroModal from "./Components/modal";
import InfoModal from "./Components/infoModal";
import { tileSheetExport, resourceExport } from "./Components/tilesheets";
import { shaderExport } from "./Components/reactionDiffusion";
import { TypeWriter, randomStart, randomMiddle, randomEnd, responseArray, randomNoRepeat } from "./Components/dialogueBar";
import { synth1, synth2, typeSound, ampenvType, ampenvNoise, autofilter, pitchArr, buttonSampler, startstopSampler } from "./Components/tone";
import VirtualAssistant from "./Components/virtualAssistant"

//Mouse Tracking
function getPosition (e) {
  const rect = e.target.getBoundingClientRect();
  return [
    (e.clientX - rect.left) / rect.width,
    (rect.bottom - e.clientY) / rect.height,
  ];
}

var mobileRule = window.matchMedia("all and (any-hover: none)");
//var mobileRule = window.matchMedia("(min-width: 600px)");

function loadResources(mobileRule){
  if (mobileRule.matches){
    setTimeout(() => document.getElementById('loadingText').style.display = "none", 100);
  } else {

  }
}

//Progress Loading
var queue = new createjs.LoadQueue(false);

queue.on("progress", event => {
  let progress = Math.floor(event.progress * 100);
  if (document.getElementById('progress')){
  document.getElementById('progress').style.width = progress+'%';
    //Random Displacement Seed
    document.getElementById("diaturb").setAttribute("seed", Math.floor(Math.random()*100));
    //Random Thickness
    document.getElementById("diamorph").setAttribute("radius", Math.random());
  }
  
  if (progress === 100){
    if (document.getElementById("intro_modal")){
      document.getElementById("intro_modal").style.backgroundColor = '#5252ff';
      }
  }
})

queue.on("complete", event => {
  if (document.getElementById('progress')){
    document.getElementById('progress').classList.add('fadeOut');
    document.getElementById('loadingText').classList.add('fadeOut');
    document.getElementById('warningId').classList.add('fadeInButton');
    }
})

queue.loadManifest(resourceExport);
queue.loadManifest(tileSheetExport);   

loadResources(mobileRule); // Call listener function at run time
mobileRule.addListener(loadResources); // Attach listener function on state changes

export default class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      //Shader
      rDShader: shaderExport.ReacDiffStatic,

      //Reaction Diffusion feedback settings
      diffusionScale: 4.0,
      backgroundMix: 0.0,
      reactBackgroundMix: 0.0,
      displacementAmount: 1.0,
      paintMix: 1.0,
      reactPaintMix: 0.0,
      reactLength: 2000,
      
      //Reaction Diffusion kill and feed rate Settings
      feed_A: 0.9 * 0.5,
      feed_B: 0.18 * 0.06,
      kill_A: 0.0545 * 0.9,
      kill_B: 0.062 * 0.09,
      
      //Blur
      factor: 5.0,

      //Texture Lookup Settings
      numCols: 8, 
      numRows: 8,
      scale: 2.0,
      indexQuantise: 1.0,
      index_colorMix: 0.0,
      transform_colorMix: 1.0,

      //Image settings
      tileSheet: tileSheetExport[1],
      lookup_res: [720.0, 720.0],
      
      diffusionDownscale: 3.3,
      diffusionDownscaleSave: 3.3,
      painterDownscaleSave: 10,     

      //Painter
      painterDownscale: 10,
      modalToggle: false,
      clearToggle: false,
      captureToggle: false,

      //Virtual Assistant Surface Capture
      fbSurfacePixels: null,

      //Question Typing
      isTyping: true,
      lastRandomStart: undefined,
      lastRandomMiddle: undefined,
      lastRandomEnd: undefined,
      lastRandomResponse: undefined,

      //Info Modal
      infoModalIsOpen: false
    }
  }

  render() {
    return (
      //whole screen canvas
      
      <div className="wrapper" id="wrapper"
        onMouseLeave={this.onMouseLeave}
        onMouseMove={this.onMouseMove}
        onMouseDown={this.onMouseDown}
        onMouseUp={this.onMouseUp}>   

          <IntroModal 
            randomQuestionGen={this.randomQuestionGen.bind(this)}
            synthStart={this.synthStart.bind(this)}
            openInfoModal={this.openInfoModal.bind(this)}
          />

          <Modal 
            className="Modal"
            id="info_modal"
            overlayClassName="Overlay"
            isOpen={this.state.infoModalIsOpen}
            onRequestClose={() => this.closeInfoModal()}
            >
            <InfoModal
              closeInfoModal={this.closeInfoModal.bind(this)}
            />
          </Modal>
      
        <div className="backgroundimage" id="backgroundimage"></div>

        <div className="dialogueBar">
          <div class="dialogueText" id="dialogueId">
            <span class="txt-type"> " "</span>
          </div>
        </div>     
        
        <div className="virtualAssistant">
          <VirtualAssistant t={this.state.fbSurfacePixels} />
        </div>
        
        <div className="painter" ref="painterwindow" id="painter">
          <FeedbackCanvas
            preload={tileSheetExport}
            rDShader={this.state.rDShader} 
            diffusionScale={this.state.diffusionScale}
            backgroundMix={this.state.backgroundMix}
            displacementAmount={this.state.displacementAmount}
            paintMix={this.state.paintMix}
            feed_A={this.state.feed_A}
            feed_B={this.state.feed_B}
            kill_A={this.state.kill_A}
            kill_B={this.state.kill_B}
            diffusionDownscale={this.state.diffusionDownscale}

            factor={this.state.factor}
            
            indexQuantise={this.state.indexQuantise}
            index_colorMix={this.state.index_colorMix}
            transform_colorMix={this.state.transform_colorMix}
            scale={this.state.scale}
            numCols={this.state.numCols}
            numRows={this.state.numRows}
            tileSheet={this.state.tileSheet}
            lookup_res={this.state.lookup_res}
            
            drawing={this.state.drawing}
            color={this.state.color}
            center={this.state.center}
            brushRadius={this.state.brushRadius}
            painterDownscale={this.state.painterDownscale}
            data={{fbSurfacePixels: this.state.fbSurfacePixels, capturePixels:this.capturePixels.bind(this)}}
            clearToggle={this.state.clearToggle}
            captureToggle={this.state.captureToggle}
            modalToggle={this.state.modalToggle}
            isTyping={this.state.isTyping}
          />
          
          {/*StartSim Button*/}
          <button             
            class="painter_button"
            id="button_sim"
            onMouseOver={() => startForwardAnimation10()}
            onMouseOut={() => startBackwardAnimation10()}
            onClick={() => this.startReaction()}>
              Run Simulation
          </button>

          {/*NextQuestion Button*/}
          <button             
            class="painter_button"
            id="button_quest"
            onMouseOver={() => startForwardAnimation11()}
            onMouseOut={() => startBackwardAnimation11()}
            onClick={() => this.randomQuestionGen()}>
              Next Question
          </button>

          {/*Info Modal Button*/}
          <button             
            class="painter_button"
            id="button_inst"
            onMouseOver={() => startForwardAnimation12()}
            onMouseOut={() => startBackwardAnimation12()}
            onClick={() => this.openInfoModal()}>
              Instructions
          </button>

        </div>
        
        <div className="settingsBar" id="settingsId">
            <SettingsBar 
              presetOne={this.presetOne.bind(this)}
              presetTwo={this.presetTwo.bind(this)}
              presetThree={this.presetThree.bind(this)}
              presetFour={this.presetFour.bind(this)}
              presetFive={this.presetFive.bind(this)}
              presetSix={this.presetSix.bind(this)}
              presetSeven={this.presetSeven.bind(this)}
              presetEight={this.presetEight.bind(this)}
              presetNine={this.presetNine.bind(this)}
            />
        </div>
        
        
    </div>
    );
  }

  // Button setState Functions
  presetOne(){
    this.setState(
      {
        //Shader
      rDShader: shaderExport.ReacDiffStatic,

      //Reaction Diffusion feedback settings
      diffusionScale: 2.0,
      backgroundMix: 0.0,
      reactBackgroundMix: 0.0,
      displacementAmount: 1.0,
      paintMix: 1.0,
      reactPaintMix: 0.0,
      reactLength: 3500,
      
      //Reaction Diffusion kill and feed rate Settings
      feed_A: 0.9 * 0.5,
      feed_B: 0.18 * 0.06,
      kill_A: 0.0545 * 0.9,
      kill_B: 0.062 * 0.09,
      
      //Blur
      factor: 5.0,

      //Texture Lookup Settings
      numCols: 8, 
      numRows: 8,
      scale: 1.0,
      indexQuantise: 1.0,
      index_colorMix: 0.0,
      transform_colorMix: 1.0,

      //Image settings
      tileSheet: tileSheetExport[Math.floor(Math.random()*3)],
      lookup_res: [720.0, 720.0],
      
      diffusionDownscale: 3.3,
      diffusionDownscaleSave: 3.3,
      painterDownscale: 10,
      painterDownscaleSave: 10,
      }
    )
    //Trigger New Chord
    synth1.triggerAttack("F#2");
    synth2.triggerAttack("B4");
    buttonSampler.triggerAttackRelease( pitchArr[Math.floor(Math.random() * 11.0)], 0.7 );
  }

  presetTwo(){
    this.setState(
      {
        //Shader
        rDShader: shaderExport.ReacDiffDown,
        
        //Reaction Diffusion feedback settings
        diffusionScale: 1.0,
        backgroundMix: 0.0,
        reactBackgroundMix: 0.0,
        displacementAmount: 1.0,
        paintMix: 1.0,
        reactPaintMix: 0.0,
        reactLength: 4000,

        //Reaction Diffusion kill and feed rate Settings
        feed_A: 0.001,
        feed_B: 0.005,
        kill_A: 0.004,
        kill_B: 0.006,
        
        //Blur
        factor: 5.0,

        //Texture Lookup Settings
        numCols: 16, 
        numRows: 16,
        scale: 10.0,
        indexQuantise: 1.0,
        index_colorMix: 1.0,
        transform_colorMix: 1.0,

        //Image settings
        tileSheet: tileSheetExport[Math.floor(Math.random()*3)+4],
        lookup_res: [720.0, 720.0],
        
        diffusionDownscale: 3.3,
        diffusionDownscaleSave: 3.3,
        painterDownscale: 10,
        painterDownscaleSave: 10,
      }
    )
    //Trigger New Chord
    synth1.triggerAttack("E2");
    synth2.triggerAttack("A4"); 
    buttonSampler.triggerAttackRelease( pitchArr[Math.floor(Math.random() * 11.0)], 0.7 );
  }

  presetThree(){
    this.setState(
      {
        //Shader
        rDShader: shaderExport.ReacDiffCircle,

        //Reaction Diffusion feedback settings
        diffusionScale: 4.0,
        backgroundMix: 0.0,
        reactBackgroundMix: 1.0,
        displacementAmount: 1.0,
        paintMix: 1.0,
        reactPaintMix: 0.0,
        reactLength: 3000,

        //Reaction Diffusion kill and feed rate Settings
        feed_A: 0.9 * 0.05,
        feed_B: 0.18 * 0.05,
        kill_A: 0.0545 * 0.09,
        kill_B: 0.062 * 0.09,
        
        //Blur
        factor: 1.0,

        //Texture Lookup Settings
        numCols: 8, 
        numRows: 1,
        scale: 2.0,
        indexQuantise: 0.0,
        index_colorMix: 1.0,
        transform_colorMix: 1.0,

        //image settings
        tileSheet: tileSheetExport[Math.floor(Math.random()*3)+8],
        lookup_res: [720.0, 720.0],
        
        diffusionDownscale: 3.3,
        diffusionDownscaleSave: 3.3,
        painterDownscale: 10,
        painterDownscaleSave: 10,
      }
    )

    synth1.triggerAttack("A2");
    synth2.triggerAttack("D4");
    buttonSampler.triggerAttackRelease( pitchArr[Math.floor(Math.random() * 11.0)], 0.7 );

  }

  presetFour(){
    this.setState(
      {
        //Shader
        rDShader: shaderExport.ReacDiffDown,

        //Reaction Diffusion feedback settings
        diffusionScale: 6.0,
        backgroundMix: 0.0,
        reactBackgroundMix: 1.0,
        displacementAmount: 1.0,
        paintMix: 1.0,
        reactPaintMix: 0.0,
        reactLength: 4000,

        //Reaction Diffusion kill and feed rate Settings
        feed_A: 0.9 * 0.05,
        feed_B: 0.18 * 0.05,
        kill_A: 0.0545 * 0.09,
        kill_B: 0.062 * 0.09,
        
        //Blur
        factor: 0.0,

        //Texture Lookup Settings
        numCols: 0.125, 
        numRows: 0.125,
        scale: 2.0,
        indexQuantise: 0.0,
        index_colorMix: 1.0,
        transform_colorMix: 0.5,

        //Image settings
        tileSheet: tileSheetExport[Math.floor(Math.random()*3)+12],
        lookup_res: [720.0, 720.0],
        
        diffusionDownscale: 3.3,
        diffusionDownscaleSave: 3.3,
        painterDownscale: 10,
        painterDownscaleSave: 10,
      }
    )
    //Trigger New Chord
    synth1.triggerAttack("D2");
    synth2.triggerAttack("G4");
    buttonSampler.triggerAttackRelease( pitchArr[Math.floor(Math.random() * 11.0)], 0.7 );
  }

  presetFive(){
    this.setState(
      {
        //Shader
        rDShader: shaderExport.ReacDiffStatic,

        //Reaction Diffusion feedback settings
        diffusionScale: 1.21,
        backgroundMix: 0.0,
        reactBackgroundMix: 0.0,
        displacementAmount: 1.0,
        paintMix: 1.0,
        reactPaintMix: 0.1,
        reactLength: 3500,

        //Reaction Diffusion kill and feed rate Settings
        feed_A: 69 * 0.01,
        feed_B: 45 * 0.01,
        kill_A: 0.12 * 0.03,
        kill_B: 0.12 * 0.03,


        //Blur
        factor: 1.0,

        //Texture Lookup Settings
        numCols: 0.125, 
        numRows: 0.125,
        scale: 2.0,
        indexQuantise: 0.0,
        index_colorMix: 0.5,
        transform_colorMix: 0.5,

        //Image settings
        tileSheet: tileSheetExport[Math.floor(Math.random()*3)+16],
        lookup_res: [720.0, 720.0],

        diffusionDownscale: 3.3,
        diffusionDownscaleSave: 3.3,
        painterDownscale: 10,
        painterDownscaleSave: 10,

      }
    )
    //Trigger New Chord
    synth1.triggerAttack("G2");
    synth2.triggerAttack("C4");
    buttonSampler.triggerAttackRelease( pitchArr[Math.floor(Math.random() * 11.0)], 0.7 );
  }

  presetSix(){
    this.setState(
      {
        //Shader
        rDShader: shaderExport.ReacDiffStatic,

        //Reaction Diffusion feedback settings
        diffusionScale: 4.0,
        backgroundMix: 0.0,
        reactBackgroundMix: 0.0,
        displacementAmount: 1.0,
        paintMix: 1.0,
        reactPaintMix: 0.1,
        reactLength: 3500,

        //Reaction Diffusion kill and feed rate Settings
        feed_A: 2,
        feed_B: 0.3,
        kill_A: 0.1,
        kill_B: 0.001,
        
        //Blur
        factor: 1.0,

        //Texture Lookup Settings
        numCols: 4, 
        numRows: 4,
        scale: 1.0,
        indexQuantise: 0.0,
        index_colorMix: 1.0,
        transform_colorMix: 1.0,

        //Image settings
        tileSheet: tileSheetExport[Math.floor(Math.random()*3)+20],
        lookup_res: [720.0, 720.0],
        
        diffusionDownscale: 3.3,
        diffusionDownscaleSave: 3.3,
        painterDownscale: 10,
        painterDownscaleSave: 10,
      }
    )
    //Trigger New Chord
    synth1.triggerAttack("C2");
    synth2.triggerAttack("F4");
    buttonSampler.triggerAttackRelease( pitchArr[Math.floor(Math.random() * 11.0)], 0.7 );  
  }

  presetSeven(){
    this.setState(
      {
        //Shader
        rDShader: shaderExport.ReacDiffStatic,

        //Reaction Diffusion feedback settings
        diffusionScale: 1.0,
        backgroundMix: 0.0,
        reactBackgroundMix: 0.0,
        displacementAmount: 1.0,
        paintMix: 1.0,
        reactPaintMix: 0.0,
        reactLength: 4000,

        //Reaction Diffusion kill and feed rate Settings
        feed_A: 0.9 * 0.05,
        feed_B: 0.1,
        kill_A: 0.0545 * 0.09,
        kill_B: 0.5,
        
        //Blur
        factor: 1.0,

        //Texture Lookup Settings
        numCols: 2, 
        numRows: 2,
        scale: 3.0,
        indexQuantise: 1.0,
        index_colorMix: 0.1,
        transform_colorMix: 0.9,

        //Image settings
        tileSheet: tileSheetExport[Math.floor(Math.random()*3)+24],
        lookup_res: [720.0, 720.0],
        
        diffusionDownscale: 3.3,
        diffusionDownscaleSave: 3.3,
        painterDownscale: 10,
        painterDownscaleSave: 10,
      }
    )
    //Trigger New Chord
    synth1.triggerAttack("F2");
    synth2.triggerAttack("Bb4");
    buttonSampler.triggerAttackRelease( pitchArr[Math.floor(Math.random() * 11.0)], 0.7 );    
  }

  presetEight(){
    this.setState(
      {
        //Shader
        rDShader: shaderExport.ReacDiffStatic,

        //Reaction Diffusion feedback settings
        diffusionScale: 0.33,
        backgroundMix: 0.0,
        reactBackgroundMix: 0.0,
        displacementAmount: 1.0,
        paintMix: 1.0,
        reactPaintMix: 0.0,
        reactLength: 4000,

        //Reaction Diffusion kill and feed rate Settings
        feed_A: 0.001,
        feed_B: 0.4,
        kill_A: 0.0545 * 0.09,
        kill_B: 0.5,

        //Blur
        factor: 5,

        //Texture Lookup Settings
        numCols: 8, 
        numRows: 8,
        scale: 2.0,
        indexQuantise: 0.0,
        index_colorMix: 0.0,
        transform_colorMix: 1.0,

        //Image settings
        tileSheet: tileSheetExport[Math.floor(Math.random()*3)+28],
        lookup_res: [720.0, 720.0],

        diffusionDownscale: 3.3,
        diffusionDownscaleSave: 3.3,
        painterDownscale: 10,
        painterDownscaleSave: 10,
      }
    )
    //Trigger New Chord
    synth1.triggerAttack("Bb2");
    synth2.triggerAttack("Eb4");
    buttonSampler.triggerAttackRelease( pitchArr[Math.floor(Math.random() * 11.0)], 0.7 );
  }

  presetNine(){
    this.setState(
      {
        //Shader
        rDShader: shaderExport.ReacDiffStatic,

        //Reaction Diffusion feedback settings
        diffusionScale: 4.0,
        backgroundMix: 0.0,
        reactBackgroundMix: 1.0,
        displacementAmount: 1.0,
        paintMix: 1.0,
        reactPaintMix: 0.0,
        reactLength: 8000,
        
        //Reaction Diffusion kill and feed rate Settings
        feed_A: 0.9,
        feed_B: 0.18,
        kill_A: 0.0545,
        kill_B: 0.062,
        
        //Blur
        factor: 5,

        //Texture Lookup Settings
        numCols: 16, 
        numRows: 1,
        scale: 10.0,
        indexQuantise: 1.0,
        index_colorMix: 1.0,
        transform_colorMix: 1.0,

        //Image settings
        tileSheet: tileSheetExport[Math.floor(Math.random()*3)+32],
        lookup_res: [720.0, 720.0],
        
        diffusionDownscale: 3.3,
        diffusionDownscaleSave: 3.3,
        painterDownscale: 10,
        painterDownscaleSave: 10,
      }
    )
    //Trigger New Chord
    synth1.triggerAttack("Eb2");
    synth2.triggerAttack("Ab4");
    buttonSampler.triggerAttackRelease( pitchArr[Math.floor(Math.random() * 11.0)], 0.7 );      
  }

  //Start the Reaction
  startReaction(){
    //do sound
    startstopSampler.triggerAttackRelease(pitchArr[1], 0.8);
    //Disable Buttons
    document.querySelectorAll('button').forEach(elem => {
      elem.disabled = true;
    });
    if (this.state.isTyping === false){
    this.setState(
      {
        paintMix: this.state.reactPaintMix,
        isTyping: true,
        backgroundMix: this.state.reactBackgroundMix
      } 
    );
    setTimeout(() => this.paintStop(), this.state.reactLength);
    const txtElement = document.querySelector('.txt-type');
    const words = "...";
    new TypeWriter(txtElement, words); } 
  }

  //Stop the Reaction
  paintStop(){
    this.captureTogglerTrue()
    this.setState(
      {
        /*paintMix: 1.0*/
        
      }, () => this.randomResponseGen()
    ) 
  }
  //Toggle Painter Clear
  clearTogglerTrue(){
    this.setState({
      painterDownscale: 0,
      diffusionDownscale: 0,
      clearToggle: true,
    }, () => 
    this.clearTogglerFalse())
  }
  //Toggle Painter Accumulate
  clearTogglerFalse(){
    this.setState({
      painterDownscale: this.state.painterDownscaleSave,
      diffusionDownscale: this.state.diffusionDownscaleSave,
      clearToggle: false
    });
  }
  //Toggle Painter Clear
  captureTogglerTrue(){
    this.setState({
      captureToggle: true,
    }, () => 
    this.captureTogglerFalse())
  }
  //Toggle Painter Accumulate
  captureTogglerFalse(){
    this.setState({
      captureToggle: false
    });
  }

  randomResponseGen(){
    //Start Sound
    typeSound.start();
    ampenvType.triggerAttackRelease(0.6);
    const txtElement = document.querySelector('.txt-type');
    let newRandomResponse = randomNoRepeat(0, responseArray.length - 1, this.state.lastRandomResponse);
    let words = responseArray[newRandomResponse];
    const wordsWait = words.length;
    var thisResponse = new TypeWriter(txtElement, words);
    this.setState(
      {
        lastRandomResponse: newRandomResponse,
      })
    loopChecker = loopChecker.bind(this);
    function loopChecker(){ 
      if (thisResponse.isCompletedCheck){
        this.setState(
          {
            isTyping: false,
          });
        //Enable Buttons
        document.querySelectorAll('button').forEach(elem => {
          elem.disabled = false;
        //Stop Sound
        typeSound.stop();
        });
      }
      else { 
        setTimeout(() => loopChecker(), 1000)
        ; }
    }
    setTimeout(() => loopChecker(), wordsWait*45);
  }

  //Generate Random Question
  randomQuestionGen(){
    //Clear Screen
    this.clearTogglerTrue();
    //Disable Buttons
    document.querySelectorAll('button').forEach(elem => {
      elem.disabled = true;
    });
    //Start Sound
    typeSound.start();
    ampenvType.triggerAttack();
    startstopSampler.triggerAttackRelease(pitchArr[0], 0.8);
    //Initialise Question Construction Array
    let questionOutputArray = ['start', 'middle', 'end']
    //Initialise Question Construction Array
    let questionOutput = 'string';
    let newRandomStart = randomNoRepeat(0, randomStart.length - 1, this.state.lastRandomStart);
    let newRandomMiddle = randomNoRepeat(0, randomMiddle.length - 1, this.state.lastRandomMiddle);
    let newRandomEnd = randomNoRepeat(0, randomEnd.length - 1, this.state.lastRandomEnd);
    questionOutputArray = [randomStart[newRandomStart], randomMiddle[newRandomMiddle], randomEnd[newRandomEnd]];
    questionOutput = questionOutputArray.join('');
    const txtElement = document.querySelector('.txt-type');
    const words = questionOutput;
    const wordsWait = words.length;
    var thisQuestion = new TypeWriter(txtElement, words);
    this.setState(
      {
        isTyping: true,
        lastRandomStart: newRandomStart,
        lastRandomMiddle: newRandomMiddle,
        lastRandomEnd: newRandomEnd,
        paintMix: 1,
        backgroundMix: 0.0     
      })
    loopChecker = loopChecker.bind(this);
    function loopChecker(){ 
      if (thisQuestion.isCompletedCheck){
        this.setState(
          {
            isTyping: false
          });
          //Enable Buttons
          document.querySelectorAll('button').forEach(elem => {
            elem.disabled = false;
          });
          //Stop Sound
          ampenvType.triggerRelease();
          typeSound.stop();
          setTimeout(() => thisQuestion = null, 1000);
      }
      else { setTimeout(() => loopChecker(), 1000); }
    }
    setTimeout(() => loopChecker(), wordsWait*45);

  }
  
    //Initialise Music
    synthStart(){

      //init panVol
      
      var panVol1 = new Tone.PanVol( -0.8, -6.);
      var panVol2 = new Tone.PanVol( 0.8, -6.);
      
      //Init Reverb
      
      var reverb = new Tone.JCReverb(1);
        var verbGain = new Tone.Gain(0.5);
        reverb.roomSize.value = 1;
        reverb.wet.value = 1;
        reverb.receive("reverb");
        reverb.chain(verbGain, Tone.Master);
      
      //Init Chorus
      var chorus = new Tone.Chorus(8, 2.5, 0).connect(Tone.Master);
  
      //init autofilter
      autofilter.start();
     
      //init bg noise  
      var noise = new Tone.Noise("pink").start();
      noise.volume.value = -16.;
      var noiseGain = new Tone.Gain(0.05);

      //init click noise  
      var spraynoise = new Tone.Noise("brown").start();
      spraynoise.volume.value = -25.;
      var eq = new Tone.EQ3(-5, 0, 0);
      //connect to env
      spraynoise.connect(ampenvNoise);
      ampenvNoise.chain(eq, Tone.Master);
  
      //init typingNoise
      //typeSound.connect(ampenvType);
      //typeSound.volume.value = +12.;
      //ampenvType.chain(Tone.Master);
  
      //route/mix buttons
      buttonSampler.connect(Tone.Master);
      buttonSampler.volume.value = +6.;
      startstopSampler.connect(Tone.Master);
      startstopSampler.volume.value = +10.;
      
      //Init LFO to synth modulation    
      var lfoHarmonicity = new Tone.LFO(1, 0.5, 2).start();
          
        lfoHarmonicity.unsync();
        lfoHarmonicity.connect(synth1.harmonicity);
        lfoHarmonicity.connect(synth2.harmonicity);
  
      var lfoAmp1 = new Tone.LFO(0.1, -60, -12).start();
      
        lfoAmp1.unsync();
        lfoAmp1.connect(synth1.volume);
    
      var lfoAmp2 = new Tone.LFO(0.1, -60, -12, {phase: 180}).start();
      
        lfoAmp2.unsync();
        lfoAmp2.connect(synth2.volume);  
  
      var masterLFO = new Tone.LFO(0.1, 0.1, 5.0).start();
  
        masterLFO.unsync();
        masterLFO.connect(lfoAmp1.frequency, lfoAmp2.frequency,synth1.harmonicity, synth2.harmonicity, autofilter.frequency  );
  
      //Synth to effects serial
      synth1.chain(autofilter, panVol1, chorus);
      synth2.chain(autofilter, panVol2, chorus);
      noise.chain(noiseGain, chorus);
      
      //Synth to Sends
      synth1.send("reverb", -16);
      synth2.send("reverb", -16);
      //noise.send("reverb", -40);
  
      //Start Other Function and Reveal Dialogue
      this.setState({
        modalToggle: true
      })
      document.getElementById("dialogueId").style.opacity = '1.0';  
    }

  //Mouse setState Functions
  onMouseDown = (e) => {
    if (this.state.modalToggle) {
      this.setState({
        reset: true,
        drawing: true,
        center: getPosition(e),    
      });
    }
    ampenvNoise.triggerAttack();
  };

  onMouseUp = () => {
    this.setState({
      drawing: false,
      reset: false
    });
    ampenvNoise.triggerRelease();
    
  };

  onMouseMove = (e) => {
    if (this.state.drawing) {
      this.setState({
        center: getPosition(e),
        brushRadius: 0.03 + 0.01 * Math.cos(Date.now() / 1000),
        color: [ (Math.cos(Date.now() / 1000) + 1.0 ) * 0.5 * 0.9, 1.0, 1.0, 1.0, ],
      });
    }
    console.log(this.state.center);
  };

  onMouseLeave = () => {
    this.setState({
      drawing: false
    });
  };

  capturePixels(fbPixelCapture){
    this.setState({
      fbSurfacePixels: fbPixelCapture
    })
  }

  openInfoModal(){
    this.setState(
      {
        infoModalIsOpen: true
      }
    );
  }

  closeInfoModal(){
    this.setState(
      {
        infoModalIsOpen: false
      }
    ) 
  }

}

