import React, { PureComponent } from "react";
import {Shaders, Node, GLSL, Uniform, LinearCopy} from "gl-react";
import { Surface } from "gl-react-dom";

const shaders = Shaders.create({
  copy: {
  frag: GLSL`
  precision highp float;
  varying vec2 uv;
  uniform sampler2D t, back;
  uniform float width;
  uniform float height;
  
  float man_dist_to_nearest_wall(vec2 uv)
  {
      float aspect = (width/height);
      float x_distance = uv.x < 1.0 - uv.x ? uv.x : 1.0 - uv.x;
      float y_distance = uv.y * aspect < (1.0 - uv.y) * aspect ? uv.y * aspect: (1.0 - uv.y) * aspect;
      return min(x_distance, y_distance);
  }

  void main(){
    
    float oldAlpha = texture2D(t, uv).a;
    vec4 final = vec4(mix(texture2D(t, uv), texture2D(back, uv + vec2(0.0, 0.0)), 0.5).rgb, oldAlpha);
    
    // edge blend  

      float offset = 0.05;
      float dist = man_dist_to_nearest_wall (uv);
      float dist_s = smoothstep( offset*.05, offset*.95, dist);
    
    gl_FragColor = final * dist_s;
    
  }
`,
}
});


export default class VirtualAssistant extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { 
      
      //Screen Size
      width: 0,
      height: 0,

    };   
    //Update Screen Size on change
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
    render() {
      const {t} = this.props;
      return(
        <Surface 
          width={((this.state.width / 16) * 14)/7}
          height={((this.state.height /9) * 8)/8}
        >
          <LinearCopy>
            <Node shader={shaders.copy} backbuffering uniforms={{ t, back: Uniform.Backbuffer, width: this.state.width, height: this.state.height }} />
          </LinearCopy>
        </Surface>
      );
    }

     //Check for window resize
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
      }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
      }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      }
  }