import { Shaders, GLSL } from "gl-react";

export const shaderExport = Shaders.create({
  ReacDiffStatic: {
    
    // implement Reaction diffusion.
    frag: GLSL` 
    
    #define PI 3.14159265358979323846

    precision highp float;
    varying vec4 vertColor;
    varying vec4 vertTexCoord;
    varying vec2 uv;
    uniform float width;
    uniform float height;

    //Params from Parent
    uniform float diffusionScale;
    uniform float backgroundMix;
    uniform float displacementAmount;
    uniform float paintMix;
    uniform float feed_A;
    uniform float feed_B;
    uniform float kill_A;
    uniform float kill_B;

    uniform sampler2D texture;
    uniform sampler2D resetTexture;

    float u = (diffusionScale / width);
    float v = (diffusionScale / height);

    vec2 rotate2D (vec2 _st, float _angle) {
      _st -= 0.5;
      _st =  mat2(cos(_angle),-sin(_angle),
                  sin(_angle),cos(_angle)) * _st;
      _st += 0.5;
      return _st;
  }

    float laplaceA(in vec2 p, in float u, in float v){
    float A = 0.05 * texture2D(texture, uv + vec2(-u,-v))[0] + 0.2 * texture2D(texture, uv + vec2(0,- v))[0] + 0.05 * texture2D(texture, uv  + vec2(u,-v))[0] +
     0.2 * texture2D(texture, uv + vec2(-u,0))[0] - 1.0 * texture2D(texture, uv + vec2(0,0))[0] + 0.2 * texture2D(texture, uv + vec2(u, 0))[0] +
    0.05 * texture2D(texture, uv + vec2(-u,v))[0] + 0.2 * texture2D(texture, uv + vec2(0,v))[0] + 0.05 * texture2D(texture, uv + vec2(u,v))[0];
    return A;
    }

    float laplaceB(in vec2 p, in float u, in float v){
    float B = 0.05 * texture2D(texture, uv + vec2(-u,-v))[1] + 0.2 * texture2D(texture, uv + vec2(0,- v))[1] + 0.05 * texture2D(texture, uv  + vec2(u,-v))[1] +
     0.2 * texture2D(texture, uv + vec2(-u,0))[1] -1.0 * texture2D(texture, uv + vec2(0,0))[1] + 0.2 * texture2D(texture, uv + vec2(u, 0))[1] +
    0.05 * texture2D(texture, uv + vec2(-u,v))[1] + 0.2 * texture2D(texture, uv + vec2(0,v))[1] + 0.05 * texture2D(texture, uv + vec2(u,v))[1];
    return B;
    }

    void main(){

    vec4 alphatest = texture2D(texture, uv);

    vec4 InputTex = texture2D(resetTexture, uv);

    if (alphatest.a == 0.0)

      gl_FragColor =  vec4(1.0, 1.0, 1.0, backgroundMix);

    else{

      float A = texture2D(texture, uv )[0] ;
      float B = texture2D(texture, uv )[1] ;

      float A_1 = A + (feed_A * laplaceA(uv, u , v) - A * B * B + kill_A * (1.0 - A)) ;
      float B_1 = B + (feed_B * laplaceB(uv, u, v) + A * B * B - (kill_B + kill_A) * B)  ;

      vec4 output1 = mix(vec4(A_1, B_1, 0.0, 1.0), InputTex , displacementAmount * 0.1);

      gl_FragColor = mix(output1, alphatest, paintMix);
      }

    //gl_FragColor = alphatest;

  }`
  },
  
  ReacDiffCircle: {
    
    // implement Reaction diffusion.
    frag: GLSL` 
    
    #define PI 3.14159265358979323846

    precision highp float;
    varying vec4 vertColor;
    varying vec4 vertTexCoord;
    varying vec2 uv;
    uniform float width;
    uniform float height;

    //Params from Parent
    uniform float diffusionScale;
    uniform float backgroundMix;
    uniform float displacementAmount;
    uniform float paintMix;
    uniform float feed_A;
    uniform float feed_B;
    uniform float kill_A;
    uniform float kill_B;

    uniform sampler2D texture;
    uniform sampler2D resetTexture;

    float u = (diffusionScale / width);
    float v = (diffusionScale / height);

    vec2 rotate2D (vec2 _st, float _angle) {
      _st -= 0.5;
      _st =  mat2(cos(_angle),-sin(_angle),
                  sin(_angle),cos(_angle)) * _st;
      _st += 0.5;
      return _st;
  }

    float laplaceA(in vec2 p, in float u, in float v){
    float A = 0.05 * texture2D(texture, uv + vec2(-u,-v))[0] + 0.2 * texture2D(texture, uv + vec2(0,- v))[0] + 0.05 * texture2D(texture, uv  + vec2(u,-v))[0] +
     0.2 * texture2D(texture, uv + vec2(-u,0))[0] - 1.0 * texture2D(texture, uv + vec2(0,0))[0] + 0.2 * texture2D(texture, uv + vec2(u, 0))[0] +
    0.05 * texture2D(texture, uv + vec2(-u,v))[0] + 0.2 * texture2D(texture, uv + vec2(0,v))[0] + 0.05 * texture2D(texture, uv + vec2(u,v))[0];
    return A;
    }

    float laplaceB(in vec2 p, in float u, in float v){
    float B = 0.05 * texture2D(texture, uv + vec2(-u,-v))[1] + 0.2 * texture2D(texture, uv + vec2(0,- v))[1] + 0.05 * texture2D(texture, uv  + vec2(u,-v))[1] +
     0.2 * texture2D(texture, uv + vec2(-u,0))[1] -1.0 * texture2D(texture, uv + vec2(0,0))[1] + 0.2 * texture2D(texture, uv + vec2(u, 0))[1] +
    0.05 * texture2D(texture, uv + vec2(-u,v))[1] + 0.2 * texture2D(texture, uv + vec2(0,v))[1] + 0.05 * texture2D(texture, uv + vec2(u,v))[1];
    return B;
    }

    void main(){

    vec4 alphatest = texture2D(texture, uv);

    //vec4 InputTex = texture2D(resetTexture, uv + vec2(0.0, 0.005));

    vec4 InputTex = texture2D(resetTexture, rotate2D(uv ,PI * 0.1));

    if (alphatest.a == 0.0)

      gl_FragColor =  vec4(1.0, 1.0, 1.0, backgroundMix);

    else{

      float A = texture2D(texture, uv )[0] ;
      float B = texture2D(texture, uv )[1] ;

      float A_1 = A + (feed_A * laplaceA(uv, u , v) - A * B * B + kill_A * (1.0 - A)) ;
      float B_1 = B + (feed_B * laplaceB(uv, u, v) + A * B * B - (kill_B + kill_A) * B)  ;

      vec4 output1 = mix(vec4(A_1, B_1, 0.0, 1.0), InputTex , displacementAmount * 0.1);

      gl_FragColor = mix(output1, alphatest, paintMix);
      }

    //gl_FragColor = alphatest;

  }`
  },
  ReacDiffDown: {
    
    // implement Reaction diffusion.
    frag: GLSL` 
    
    #define PI 3.14159265358979323846

    precision highp float;
    varying vec4 vertColor;
    varying vec4 vertTexCoord;
    varying vec2 uv;
    uniform float width;
    uniform float height;

    //Params from Parent
    uniform float diffusionScale;
    uniform float backgroundMix;
    uniform float displacementAmount;
    uniform float paintMix;
    uniform float feed_A;
    uniform float feed_B;
    uniform float kill_A;
    uniform float kill_B;

    uniform sampler2D texture;
    uniform sampler2D resetTexture;

    float u = (diffusionScale / width);
    float v = (diffusionScale / height);

    vec2 rotate2D (vec2 _st, float _angle) {
      _st -= 0.5;
      _st =  mat2(cos(_angle),-sin(_angle),
                  sin(_angle),cos(_angle)) * _st;
      _st += 0.5;
      return _st;
  }

    float laplaceA(in vec2 p, in float u, in float v){
    float A = 0.05 * texture2D(texture, uv + vec2(-u,-v))[0] + 0.2 * texture2D(texture, uv + vec2(0,- v))[0] + 0.05 * texture2D(texture, uv  + vec2(u,-v))[0] +
     0.2 * texture2D(texture, uv + vec2(-u,0))[0] - 1.0 * texture2D(texture, uv + vec2(0,0))[0] + 0.2 * texture2D(texture, uv + vec2(u, 0))[0] +
    0.05 * texture2D(texture, uv + vec2(-u,v))[0] + 0.2 * texture2D(texture, uv + vec2(0,v))[0] + 0.05 * texture2D(texture, uv + vec2(u,v))[0];
    return A;
    }

    float laplaceB(in vec2 p, in float u, in float v){
    float B = 0.05 * texture2D(texture, uv + vec2(-u,-v))[1] + 0.2 * texture2D(texture, uv + vec2(0,- v))[1] + 0.05 * texture2D(texture, uv  + vec2(u,-v))[1] +
     0.2 * texture2D(texture, uv + vec2(-u,0))[1] -1.0 * texture2D(texture, uv + vec2(0,0))[1] + 0.2 * texture2D(texture, uv + vec2(u, 0))[1] +
    0.05 * texture2D(texture, uv + vec2(-u,v))[1] + 0.2 * texture2D(texture, uv + vec2(0,v))[1] + 0.05 * texture2D(texture, uv + vec2(u,v))[1];
    return B;
    }

    void main(){

    vec4 alphatest = texture2D(texture, uv);

    vec4 InputTex = texture2D(resetTexture, uv + vec2(0.0, 0.005));

    if (alphatest.a == 0.0)

      gl_FragColor =  vec4(1.0, 1.0, 1.0, backgroundMix);

    else{

      float A = texture2D(texture, uv )[0] ;
      float B = texture2D(texture, uv )[1] ;

      float A_1 = A + (feed_A * laplaceA(uv, u , v) - A * B * B + kill_A * (1.0 - A)) ;
      float B_1 = B + (feed_B * laplaceB(uv, u, v) + A * B * B - (kill_B + kill_A) * B)  ;

      vec4 output1 = mix(vec4(A_1, B_1, 0.0, 1.0), InputTex , displacementAmount * 0.1);

      gl_FragColor = mix(output1, alphatest, paintMix);
      }

    //gl_FragColor = alphatest;

  }`
  }
});

