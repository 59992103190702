import React from "react";
import { Uniform, Shaders, Node, GLSL } from "gl-react";

const shaders = Shaders.create({
  TextureLookup: {

    frag: GLSL`

    #define PI 3.14159265358979323846

    precision highp float;

    varying vec2 uv;

        // sampler uniforms

    uniform sampler2D tileSheet;
    uniform sampler2D lookup;
    uniform sampler2D back;
    uniform vec2 lookup_res;
    uniform float width;
    uniform float height;

        //Params

    uniform float numCols;
    uniform float numRows;
    uniform float scale;
        
      //Offset not currently implemented
      //uniform float offset;

    uniform float index_colorMix;
    uniform float transform_colorMix;
    uniform float indexQuantise;

      //rotation fucntion

    vec2 rotate2D (vec2 _st, float _angle) {
        _st -= 0.5;
        _st =  mat2(cos(_angle),-sin(_angle),
                    sin(_angle),cos(_angle)) * _st;
        _st += 0.5;
        return _st;
    }

      //remap

    float map(float value, float min1, float max1, float min2, float max2) {
      return min2 + (value - min1) * (max2 - min2) / (max1 - min1);
    }

      //compute distance to edge of canvas

        float man_dist_to_nearest_wall(vec2 uv)
    {
        float aspect = (width/height);
        float x_distance = uv.x < 1.0 - uv.x ? uv.x : 1.0 - uv.x;
        float y_distance = uv.y * aspect < (1.0 - uv.y) * aspect ? uv.y * aspect: (1.0 - uv.y) * aspect;
        return min(x_distance, y_distance);
    }

      // start

    void main() {

    float NumTiles = (numCols * numRows);

      //get texel with no filtering, texelFetch PORT where (2 * texture coordinate * texture side-length) / (2 * texture size)

    vec2 texelCoord = (2.0 * uv * lookup_res) / (2.0 * lookup_res);

    vec4 lookuptex = texture2D(lookup, texelCoord);

    float A_1 = lookuptex.r;
    float B_1 = lookuptex.g;
    
    float inputVal1 = mix(A_1, B_1, index_colorMix);
    float inputVal2 = mix(A_1, B_1, transform_colorMix);
        
      // calculate the index - added option for mixing between truncated and float of index location
    
    float index = 0.0;
    index += step(1., mod(uv.x,2.0));
    index += step(1., mod(uv.y,2.0))*2.0;    

    float x_index = mix((inputVal1 * NumTiles), floor(inputVal1 * NumTiles), indexQuantise);
    float y_index = mix((inputVal1 * numCols), floor(inputVal1 * numCols), indexQuantise);

      // calculate the X and Y UV coordinates

    float posX = ((mod((x_index), numCols)) * (1. / numCols));
    float posY = (((y_index * NumTiles) / numRows) * (1. / NumTiles));
    float posXPlusOne = ((mod((x_index + 1.0), numCols)) * (1. / numCols));

    vec2 newUV = vec2(posX,posY);
    vec2 newUVoffset = vec2(posXPlusOne, posY);
    
      // calculate rotation
    
    float angle = (-0.5 * map(  NumTiles * (uv.s + 0.5 * uv.t + 0.5), 0.0, NumTiles, -2.0 , 2.0)) * PI;  
        
      // calculate the coordinate bounds of the first corner tile

    vec2 CornerTileUV = mod(rotate2D(uv, angle) * scale * clamp((inputVal1 + 0.1),0.5,1.0), (1.0 / numRows));
      vec2 CornerTileUV_FlipX = mod(rotate2D(vec2(1.0 - uv.x, uv.y), angle) * scale * clamp((inputVal1 + 0.1),0.5,1.0), (1.0 / numRows));
      vec2 CornerTileUV_FlipY = mod(rotate2D(vec2(uv.x, 1.0 - uv.y), angle) * scale * clamp((inputVal1 + 0.1),0.5,1.0), (1.0 / numRows));
      
    //calulate blend coordinates for interpolation
    
    vec2 UV1 = mod(CornerTileUV + newUV, 1.0);
      vec2 UV1_FlipX = mod(CornerTileUV_FlipX + newUV, 1.0);
      vec2 UV1_FlipY = mod(CornerTileUV_FlipY + newUV, 1.0);
  
    vec2 UV2 = mod(CornerTileUV + newUVoffset, 1.0);
      vec2 UV2_FlipX = mod(CornerTileUV_FlipX + newUVoffset, 1.0);
      vec2 UV2_FlipY = mod(CornerTileUV_FlipY + newUVoffset, 1.0);

      //implement mirrored edge blending
    
    float blendAmt = 1.0 * inputVal1;
    float blendEnd = 0.5;
  
    vec4 blend1 = mix(mix(texture2D(tileSheet, UV1), texture2D(tileSheet, UV1_FlipX), smoothstep(blendAmt, blendEnd, abs(UV1.x - 0.5)) * 0.5),texture2D(tileSheet, UV1_FlipY), smoothstep(blendAmt, blendEnd, abs(UV1.y - 0.5)) * 0.5);
    vec4 blend2 = mix(mix(texture2D(tileSheet, UV2), texture2D(tileSheet, UV2_FlipX), smoothstep(blendAmt, blendEnd, abs(UV2.x - 0.5)) * 0.5),texture2D(tileSheet, UV2_FlipY), smoothstep(blendAmt, blendEnd, abs(UV2.y - 0.5)) * 0.5);
    
    vec4 color = mix(blend1, blend2, mod(inputVal1 * NumTiles , 1.0 ));

      //multiply by alpha mask
    
    float newAlpha = color.a * lookuptex.a;
    
      //calculate persistance  

    vec4 persistance = vec4(mix(color, texture2D(back, uv + vec2(0.0, 0.0)), lookuptex.g));    

      //vec4 final = vec4(persistance.rgb * vec3(newAlpha), newAlpha);  
    
    vec4 final = persistance * vec4(newAlpha);  
  
      // edge blend  

    float offset = 0.01;
    float dist = man_dist_to_nearest_wall (uv);
    float dist_s = smoothstep( offset*.05, offset*.95, dist);
    
    gl_FragColor = final * dist_s;

    }

    `
  }
});

//Texture lookup operation
export const TextureLookupExport = (({tileSheet, lookup, lookup_res, numCols, numRows, scale, indexQuantise, index_colorMix, transform_colorMix, width, height}) =>
  <Node shader={shaders.TextureLookup} 
  backbuffering
  uniforms={{ 
    tileSheet,
    lookup,
    lookup_res,
    numCols,
    numRows,
    scale,
    indexQuantise,
    index_colorMix,
    transform_colorMix,
    width,
    height,
    back: Uniform.Backbuffer}} />);

