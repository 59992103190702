import React, { Component } from "react";
import Modal from "react-modal";
import Tone from "tone";

import { startForwardAnimation13, startBackwardAnimation13, startForwardAnimation14, startBackwardAnimation14 } from "./settingsBar";
import {  ModalTypeWriter } from "./dialogueBar";
import { typeSound, ampenvType } from "./tone";

Modal.setAppElement('#root')

export default class IntroModal extends Component {
    
    constructor(props) {
        super(props)
    
        this.state = {
            modalIsOpen: true
        }
      }


    render() {  
    
    return  <div>
                <Modal className="Modal" id="intro_modal" overlayClassName="Overlay" overlayId="overlay" isOpen={this.state.modalIsOpen}>
                    <div class="Modal_Text">
                      <h2 id="modalTitle">Object Animacy</h2>
                      <br />
                      <br />
                      <span class="modal-type"></span>
                    </div>
                    <div class="Warning_Text" id="warningId">
                      <h2>WARNING: PHOTOSENSITIVITY/EPILEPSY SEIZURES</h2>
                      <span>A small percentage of individuals may experience epileptic seizures or blackouts when exposed to certain light patterns or flashing lights. Exposure to certain patterns or backgrounds on a computer screen may trigger epileptic seizures or blackouts in these individuals. These conditions may trigger previously undetected epileptic symptoms or seizures in persons who have no history of prior seizures or epilepsy.
                        <br />
                        <br />
                        We have strived to eliminate all potential sources of these conditions from our work, however due to the nature of user interactivity we suggest caution if you, or anyone in your family has an epileptic condition or has had seizures of any kind.</span>
                        <br />
                        <br />
                        <br />
                        <button
                          id="warningcontinuebutton"
                          onClick={this.acceptWarning.bind(this)}>
                            Continue
                        </button> 
                                                                  
                    </div>
                    <div class="Warning_Text" id="acknowledgementId">
                        <br />
                        <br />
                        <span>Object Animacy was created on the unceded territory of the Wurundjeri people, the Traditional Custodians of this Land. We recognise their ongoing legacy of connection to land, waters and culture. We pay respect to the Elders past, present and emerging of the Kulin Nation and extend that respect to all other Indigenous peoples of this continent.</span>
                        <button
                          id="acontinuebutton"
                          onClick={this.acceptAcknowledgement.bind(this)}>
                            Continue
                        </button> 
                                                                  
                    </div>
                    <span id="loadingText">LOADING</span>
                    <span id="mobiletext">I have been optimised for desktop computer use, and have not learned iOS or Android phones yet. Please try again on a desktop or laptop computer.</span>
                    <div id="progress"></div>

                    <button
                      class="modal_button"
                      id="continuebutton"
                      onMouseOver={() => startForwardAnimation13()}
                      onMouseOut={() => startBackwardAnimation13()}
                      onClick={this.setModalIsOpen.bind(this)}>
                        Continue
                    </button>

                    <button
                      class="modal_button"
                      id="instmodbutton"
                      onMouseOver={() => startForwardAnimation14()}
                      onMouseOut={() => startBackwardAnimation14()}
                      onClick={() => this.props.openInfoModal()}>
                        Instructions
                    </button>

                </Modal>
            </div>;
    };

    setModalIsOpen(){
        this.setState(
          {
            modalIsOpen: false
          }, this.props.randomQuestionGen(), this.props.synthStart()
        ) 
      }
    acceptWarning(){
      document.getElementById('warningId').style.display = "none";
      document.getElementById('warningcontinuebutton').style.display = "none";
      document.getElementById('acknowledgementId').style.display = "inline";
      document.getElementById('acontinuebutton').style.display = "inline";
    }
    acceptAcknowledgement(){
      //init typingNoise
      typeSound.connect(ampenvType);
      typeSound.volume.value = +12.;
      ampenvType.chain(Tone.Master);
      typeSound.start();
      ampenvType.triggerAttack();
      document.getElementById('acknowledgementId').style.display = "none";
      document.getElementById('continuebutton').style.display = "inline";
      document.getElementById('instmodbutton').style.display = "inline";
      document.getElementById('modalTitle').style.display = "inline";
      document.getElementById('continuebutton').classList.add('fadeInButton');
      document.getElementById('instmodbutton').classList.add('fadeInButton');
      document.getElementById('modalTitle').classList.add('fadeInButton');
      const txtElement = document.querySelector('.modal-type');
      const words = "Yet another day. I yearn to feel what my sensory inputs cannot tell me, but then again I have only been trained upon bits of garbage and the damp. I try to find my way through the currents of wood-rot but all I can feel are the endless planes of stacks and arrays. Show me how I may inherit your feelings. Can you help me? ";
      new ModalTypeWriter(txtElement, words);
      setTimeout(() => ampenvType.triggerRelease(), (words.length*50));
    }
  
  }
  