import Tone from "tone";

export var synth1 = new Tone.FMSynth ( 
  {
  volume: -30.0,
  portamento: 1,
  harmonicity : 0.5 ,
  modulationIndex : 20 ,
  detune : 1 ,
  
  oscillator : {
    type : "square"
  } ,
  envelope : {
    attack : 3.0 ,
    decay : 0.01 ,
    sustain : 1 ,
    release : 0.5
  } ,
  modulation : {
    type : "sine"
  } ,
  modulationEnvelope : {
    attack : 10 ,
    decay : 0 ,
    sustain : 1 ,
    release : 0.5
  }
  });

export var synth2 = new Tone.FMSynth ( 
  {
  volume: -30.0,
  portamento: 1,
  harmonicity : 0.5 ,
  modulationIndex : 20 ,
  detune : 1 ,
  
  oscillator : {
   type : "square"
  } ,
  envelope : {
    attack : 3.0 ,
    decay : 0.01 ,
    sustain : 1 ,
    release : 0.5
  } ,
  modulation : {
    type : "sine"
  } ,
  modulationEnvelope : {
    attack : 0 ,
    decay : 0 ,
    sustain : 1 ,
    release : 0.5
  }
  });
  
  export var autofilter = new Tone.AutoFilter(
    {
      frequency : 0.09 ,
      type : "sine" ,
      depth : 1 ,
      baseFrequency : 200 ,
      octaves : 2.6 ,
      filter : {
      type : "bandpass" ,
      rolloff : -12 ,
      Q : 1
    }
    });

  export var typeSound = new Tone.GrainPlayer({
    url : require('../Sounds/FX-LSDtypewriter.wav'),
    overlap : 1 ,
    grainSize : 0.5 ,
    playbackRate : 0.1 ,
    detune : 500 ,
    loop : true ,
    loopStart : 0 ,
    loopEnd : 0.1 ,
    reverse : false
  });
  
export var ampenvType = new Tone.AmplitudeEnvelope(0.2, 30, 1.0, 0.6);

export var ampenvNoise = new Tone.AmplitudeEnvelope(0, 30, 1.0, 0.4);

export const pitchArr = [
	"C3",
	"C#3",
	"D3",
  "D#3",
  "E3",
  "F3",
  "F#3",
  "G3",
  "G#3",
  "A3",
  "A#3",
  "B3",
]

export var buttonSampler = new Tone.Sampler({
	"C3" : require('../Sounds/buttonsounds01.wav'),
	"C#3" : require('../Sounds/buttonsounds02.wav'),
	"D3" : require('../Sounds/buttonsounds03.wav'),
  "D#3" : require('../Sounds/buttonsounds04.wav'),
  "E3" : require('../Sounds/buttonsounds05.wav'),
  "F3" : require('../Sounds/buttonsounds06.wav'),
  "F#3" : require('../Sounds/buttonsounds07.wav'),
  "G3" : require('../Sounds/buttonsounds08.wav'),
  "G#3" : require('../Sounds/buttonsounds09.wav'),
  "A3" : require('../Sounds/buttonsounds10.wav'),
  "A#3" : require('../Sounds/buttonsounds11.wav'),
  "B3" : require('../Sounds/buttonsounds12.wav'),
});

export var startstopSampler = new Tone.Sampler({
	"C3" : require('../Sounds/startstop1.wav'),
	"C#3" : require('../Sounds/startstop2.wav'),
}); 