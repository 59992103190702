import { Shaders, GLSL } from "gl-react";

export const paintShaderExport = Shaders.create({
  Paint: {
    
    frag: GLSL`
    
    precision highp float;
    varying vec2 uv;
    uniform sampler2D back;
    uniform bool drawing;
    uniform bool resizing;
    uniform vec2 center;

      //params
    
    uniform vec4 color;
    uniform float brushRadius;

        vec3 hsv2rgb(vec3 c)
    {
        vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);
        vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);
        return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);
    }

    void main() {  

    vec4 colorhsv = vec4(hsv2rgb(color.rgb * 0.9), color.a);
    
        if (drawing == true){
          vec2 d = uv - center;
            if (length(d) < brushRadius) {
              gl_FragColor = colorhsv;
            }
            else {discard;}
            }
          else {discard;}
      }

    `
  }
});
